<template>
  <v-footer id="ae-auth-footer">
    <v-spacer />
    <div class="text-right grey--text text--darken-2">
      Copyright &copy; {{ new Date().getFullYear() }} Athlete Era Technologies
      Ltd.
      <v-divider vertical class="mx-2" />
      <a
        href="https://www.athleteera.app/"
        target="_blank"
        class="text-decoration-none grey--text text--darken-2"
      >
        About Us
      </a>
    </div>
  </v-footer>
</template>

<script>
export default {
  name: 'DashboardCoreFooter',
};
</script>
